export const Apis = {
  Token: {
    EndPoint: 'https://api.pictory.ai/pictoryapis/v1/oauth2/token',
    Method: 'post',
  },
  StoryboardCreate: {
    EndPoint: 'https://api.pictory.ai/pictoryapis/v1/video/storyboard',
    Method: 'post',
  },
  getFont: {
    EndPoint: 'https://api.pictory.ai/pictoryapis/v1/video/storyboard/fonts',
    Method: 'get',
  },
};
