import React from 'react';
import FormContainer from './FormContainer';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Flex, Button, Box, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getContent } from '../actions/contentActions';
import Loader from './Loading';
import Message from './Message';

const VideoRender = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const contentResult = useSelector((state) => state.getContentReducer);
  const { loading, content, error } = contentResult;

  useEffect(() => {
    if (id) {
      dispatch(getContent(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <Flex mb="2">
        <Button color="Black" size="lg" as={Link} to="/dashboard">
          Back
        </Button>
      </Flex>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : content.status === 'in-progress' ? (
        <Flex>
          <Message type="info">rendering ...</Message>
        </Flex>
      ) : (
        <Flex
          boxShadow="lg"
          rounded="md"
          bgColor="black"
          // height="640px"
          width={{ base: '100%' }}
          height={{ base: '640px', md: '470px', sm: '200px' }}
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          position="relative"
        >
          <video controls style={{ width: '100%', height: '100%' }}>
            <source src={content.videoURL} type="video/mp4" />
          </video>
        </Flex>
      )}
    </>
  );
};

export default VideoRender;
