import { Flex } from '@chakra-ui/react';

const FormContainer = ({ children, width = 'xl' }) => {
  return (
    <Flex direction="column" boxShadow="lg" rounded="md" p="10" width={width} alignItems="center">
      {children}
    </Flex>
  );
};

export default FormContainer;
