import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Stack,
  Skeleton,
  Box,
  Flex,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { listContents } from '../actions/contentActions';
import Message from '../components/Message';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contentList = useSelector((state) => state.contentReducer);
  const { loading, contents, error } = contentList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(listContents());
    } else {
      navigate('/login');
    }
  }, [dispatch, userInfo]);

  return (
    <>
      <Flex mb="5">
        <Button color="Black" size="lg" as={Link} to="/">
          Generate Video +
        </Button>
      </Flex>
      {loading ? (
        <Stack>
          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />

          <Skeleton height="40px" fadeDuration={4} bg="blue.500" color="white" />
        </Stack>
      ) : error ? (
        <Message type="error">{error}</Message>
      ) : (
        <Box bgColor="white" rounded="lg" shadow="lg" px="5" py="5">
          <Table variant="striped" colorScheme="black" size="lg">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Duration</Th>
                <Th>Mood</Th>
                <Th>Resolution</Th>
                <Th textAlign="right">Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {contents?.data?.map((i) => (
                <Tr key={i._id}>
                  <Td>{i.title.slice(0, 100)}</Td>
                  <Td>{i.prompts?.duration}</Td>
                  <Td>{i.prompts?.mood || ''}</Td>
                  <Td>
                    {(i.prompts?.resolution &&
                      i.prompts?.resolution?.width + '*' + i.prompts?.resolution?.height) ||
                      ''}
                  </Td>
                  <Td textAlign="right">
                    {i.status === 'in-progress' ? (
                      i.status
                    ) : i.status === 'Completed' && i.stage === 'Fail' ? (
                      'Failed'
                    ) : (
                      <Button
                        colorScheme="pink"
                        as={Link}
                        to={`/stream/${i._id}`}
                        state={{ ContentUrl: `${i.videoURL}` }}
                      >
                        Open
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );
};

export default Dashboard;
