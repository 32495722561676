import { Flex, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Flex as="footer" justifyContent="center" py="5">
      <Text>Copyright © 2024 Stories2Life Digital Private Limited.</Text>
    </Flex>
  );
};

export default Footer;
