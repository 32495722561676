import {
  Button,
  Flex,
  Text,
  FormControl,
  Box,
  FormLabel,
  Select,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Loader from '../components/Loading';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { moodType, durations, resolutionsType } from '../data/formOptionData';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tokenData = useSelector((state) => state.getToken);
  const { loading, error } = tokenData;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [prompt, setPrompt] = useState('');
  const [mood, setMood] = useState('');
  const [duration, setDuration] = useState('');
  const [resolution, setResolution] = useState('');

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }
  }, [navigate, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();

    const resolutionvalue = resolutionsType.find((i) => {
      if (i.name === resolution) return i.value;
    });

    localStorage.setItem('UserPrompt', JSON.stringify({ prompt, mood, duration, resolutionvalue }));

    navigate('/script');
  };
  return (
    <>
      <Flex mb="5">
        <Button color="Black" size="lg" as={RouterLink} to="/dashboard">
          Back to Dashboard
        </Button>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Box>
          <Text fontSize="2xl">
            Unleash Your Creativity, Effortlessly: Transform Stories into Stunning Videos in
            seconds.
          </Text>
        </Box>
      </Flex>

      <Flex p="5" justifyContent="center" alignItems="center">
        {loading ? (
          <Loader />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <FormContainer width="full">
            <form onSubmit={submitHandler}>
              <FormControl id="prompt" mb="3" isRequired>
                <FormLabel>Prompt :-</FormLabel>
                <Textarea
                  type="text"
                  size="lg"
                  placeholder="Briefly Describe your story here."
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
              </FormControl>

              <FormControl id="mood" mb="3" isRequired>
                <FormLabel>Mood :-</FormLabel>
                <Select
                  placeholder="-- Select --"
                  value={mood}
                  onChange={(e) => setMood(e.target.value)}
                >
                  {moodType.map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl id="duration" mb="3" isRequired>
                <FormLabel>Duration :-</FormLabel>
                <RadioGroup name="duration" value={duration} onChange={(e) => setDuration(e)}>
                  <Stack spacing={5} direction="row">
                    {durations.map((i) => (
                      <Radio key={i} value={i} size="md" colorScheme="pink" variant="outline">
                        {i}
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              </FormControl>

              <FormControl id="resolution" mb="3" isRequired>
                <FormLabel>Resolution :-</FormLabel>
                <Select
                  placeholder="-- Select --"
                  value={resolution}
                  onChange={(e) => setResolution(e.target.value)}
                >
                  {resolutionsType.map((i, j) => (
                    <option key={j} value={i.name}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Button type="submit" color="#48BB78" mt="4" px="3">
                Generate Script
              </Button>
            </form>
          </FormContainer>
        )}
      </Flex>
    </>
  );
};
export default HomeScreen;
