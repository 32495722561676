import {
  TOKEN_REQUEST,
  TOKEN_FAIL,
  TOKEN_SUCCESS,
  SCRIPT_FAIL,
  SCRIPT_REQUEST,
  SCRIPT_SUCCESS,
  CREATE_CONTENT_FAIL,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_SUCCESS,
  CREATE_STORYBOARD_RESET,
  CONTENTS_FAIL,
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  FONT_FAIL,
  FONT_REQUEST,
  FONT_SUCCESS,
  GET_CONTENT_FAIL,
  GET_CONTENT_REQUEST,
  GET_CONTENT_SUCCESS,
} from '../constants/contentConstants';

export const tokenReducer = (state = { token: {} }, action) => {
  switch (action.type) {
    case TOKEN_REQUEST:
      return { ...state, loading: true };
    case TOKEN_SUCCESS:
      return { loading: false, token: action.payload };
    case TOKEN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const contentReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case CONTENTS_REQUEST:
      return { ...state, loading: true };
    case CONTENTS_SUCCESS:
      return { loading: false, contents: action.payload };
    case CONTENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fontReducer = (state = { font: [] }, action) => {
  switch (action.type) {
    case FONT_REQUEST:
      return { ...state, loading: true };
    case FONT_SUCCESS:
      return { loading: false, font: action.payload };
    case FONT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const scriptReducer = (state = { script: {} }, action) => {
  switch (action.type) {
    case SCRIPT_REQUEST:
      return { ...state, loading: true };
    case SCRIPT_SUCCESS:
      return { loading: false, script: action.payload };
    case SCRIPT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createContentReducer = (state = { contentCreated: false }, action) => {
  switch (action.type) {
    case CREATE_CONTENT_REQUEST:
      return { ...state, loading: true };
    case CREATE_CONTENT_SUCCESS:
      return { loading: false, contentCreated: action.payload };
    case CREATE_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_STORYBOARD_RESET:
      return { contentCreated: false };
    default:
      return state;
  }
};

export const getContentReducer = (state = { content: {} }, action) => {
  switch (action.type) {
    case GET_CONTENT_REQUEST:
      return { ...state, loading: true };
    case GET_CONTENT_SUCCESS:
      return { loading: false, content: action.payload };
    case GET_CONTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
