import axios from 'axios';
import {
  USER_LOGIN_SUCCESS,
  USER_LOGIN_REQUEST,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
} from '../constants/userConstants';
import { StL_Apis } from '../api/stlBackApi';

axios.interceptors.request.use(
  (config) => {
    config.timeout = 420000;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const login = (name, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const {
      data: { data },
    } = await axios.post(StL_Apis.UserLogin, { name, password }, config);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('UserPrompt');
  dispatch({ type: USER_LOGOUT });
};
