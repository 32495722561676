export const moodType = ['History', 'Banking History', 'Travel', 'Life story', 'Did you know'];

export const durations = ['1m', '2m', '5m', '10m', '15m', '20m'];

export const resolutionsType = [
  {
    name: 'Vertical 1080*1920',
    value: {
      videoWidth: 1080,
      videoHeight: 1920,
    },
  },
  {
    name: 'Horizontal 1920*1080',
    value: {
      videoWidth: 1920,
      videoHeight: 1080,
    },
  },
  {
    name: 'Square 1080*1080',
    value: {
      videoWidth: 1080,
      videoHeight: 1080,
    },
  },
];

//ai voice over sample data
export const AiVoiceOver = [
  {
    name: 'Adeline, female',
    value: 'Adeline',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-E_FEMALE.mp3',
  },
  {
    name: 'Adison, female',
    value: 'Adison',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-H_FEMALE.mp3',
  },
  {
    name: 'Aditi, female',
    value: 'Aditi',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Aditi_sample.mp3',
  },
  {
    name: 'Aiden, male',
    value: 'Aiden',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-A_MALE.mp3',
  },
  {
    name: 'Alice, female',
    value: 'Alice',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-G_FEMALE.mp3',
  },
  {
    name: 'Amy, female',
    value: 'Amy',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Amy_100_sample.mp3',
  },
  {
    name: 'Aria, female',
    value: 'Aria',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Aria_en-NZ.mp3',
  },
  {
    name: 'Arthur, male',
    value: 'Arthur',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Neural2-B_MALE.mp3',
  },
  {
    name: 'Austin, male',
    value: 'Austin',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-I_MALE.mp3',
  },
  {
    name: 'Ava, female',
    value: 'Ava',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-C_FEMALE.mp3',
  },
  {
    name: 'Aveek, male',
    value: 'Aveek',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-IN-Wavenet-B_MALE.mp3',
  },
  {
    name: 'Ayanda, female',
    value: 'Ayanda',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Ayanda_en-ZA.mp3',
  },
  {
    name: 'Belinda, female',
    value: 'Belinda',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-F_FEMALE.mp3',
  },
  {
    name: 'Ben, male',
    value: 'Ben',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Wavenet-D_MALE.mp3',
  },
  {
    name: 'Brian, male',
    value: 'Brian',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Brian_100_sample.mp3',
  },
  {
    name: 'Chris, male',
    value: 'Chris',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Wavenet-B_MALE.mp3',
  },
  {
    name: 'Debby, female',
    value: 'Debby',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-E_FEMALE.mp3',
  },
  {
    name: 'Edith, female',
    value: 'Edith',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Neural2-C_FEMALE.mp3',
  },
  {
    name: 'Emma, female',
    value: 'Emma',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Emma_100_sample.mp3',
  },
  {
    name: 'Eugene, male',
    value: 'Eugene',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-A_MALE.mp3',
  },
  {
    name: 'Fiona, female',
    value: 'Fiona',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Wavenet-A_FEMALE.mp3',
  },
  {
    name: 'Hazel, female',
    value: 'Hazel',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Neural2-A_FEMALE.mp3',
  },
  {
    name: 'Heather, female',
    value: 'Heather',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Neural2-F_FEMALE.mp3',
  },
  {
    name: 'Heidi, female',
    value: 'Heidi',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Wavenet-C_FEMALE.mp3',
  },
  {
    name: 'Henry, male',
    value: 'Henry',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Neural2-D_MALE.mp3',
  },
  {
    name: 'Jackson, male',
    value: 'Jackson',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-I_MALE.mp3',
  },
  {
    name: 'James, male',
    value: 'James',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Neural2-D_MALE.mp3',
  },
  {
    name: 'Jeff, male',
    value: 'Jeff',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-J_MALE.mp3',
  },
  {
    name: 'Jessica, female',
    value: 'Jessica',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-G_FEMALE.mp3',
  },
  {
    name: 'Joanna, female',
    value: 'Joanna',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Joanna_100_sample.mp3',
  },
  {
    name: 'Joey, male',
    value: 'Joey',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Joey_en-US.mp3',
  },
  {
    name: 'Kendra, female',
    value: 'Kendra',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Kendra_100_sample.mp3',
  },
  {
    name: 'Kimberly, female',
    value: 'Kimberly',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Kimberly_100_sample.mp3',
  },
  {
    name: 'Liam, male',
    value: 'Liam',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-J_MALE.mp3',
  },
  {
    name: 'Lisa, female',
    value: 'Lisa',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Wavenet-A_FEMALE.mp3',
  },
  {
    name: 'Martin, male',
    value: 'Martin',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-D_MALE.mp3',
  },
  {
    name: 'Mary, female',
    value: 'Mary',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Wavenet-C_FEMALE.mp3',
  },
  {
    name: 'Matthew, male',
    value: 'Matthew',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Matthew_100_sample.mp3',
  },
  {
    name: 'Nicole, female',
    value: 'Nicole',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Nicole_sample.mp3',
  },
  {
    name: 'Noah, male',
    value: 'Noah',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Neural2-B_MALE.mp3',
  },
  {
    name: 'Oliver, male',
    value: 'Oliver',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Wavenet-D_MALE.mp3',
  },
  {
    name: 'Olivia, female',
    value: 'Olivia',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Olivia_en-AU.mp3',
  },
  {
    name: 'Phil, male',
    value: 'Phil',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Wavenet-B_MALE.mp3',
  },
  {
    name: 'Priti, female',
    value: 'Priti',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-IN-Wavenet-D_FEMALE.mp3',
  },
  {
    name: 'Raveena, female',
    value: 'Raveena',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Raveena_sample.mp3',
  },
  {
    name: 'Ruby, female',
    value: 'Ruby',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Neural2-C_FEMALE.mp3',
  },
  {
    name: 'Russell, male',
    value: 'Russell',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Russell_sample.mp3',
  },
  {
    name: 'Safa, female',
    value: 'Safa',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-GB-Wavenet-F_FEMALE.mp3',
  },
  {
    name: 'Salli, female',
    value: 'Salli',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/polly/samples/Salli_en-US.mp3',
  },
  {
    name: 'Scott, male',
    value: 'Scott',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-B_MALE.mp3',
  },
  {
    name: 'Shaveen, male',
    value: 'Shaveen',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-IN-Wavenet-C_MALE.mp3',
  },
  {
    name: 'Shreya, female',
    value: 'Shreya',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-IN-Wavenet-A_FEMALE.mp3',
  },
  {
    name: 'Sophia, female',
    value: 'Sophia',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-F_FEMALE.mp3',
  },
  {
    name: 'Stacy, female',
    value: 'Stacy',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-H_FEMALE.mp3',
  },
  {
    name: 'Steffi, female',
    value: 'Steffi',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Wavenet-C_FEMALE.mp3',
  },
  {
    name: 'Tom, male',
    value: 'Tom',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-US-Neural2-D_MALE.mp3',
  },
  {
    name: 'Willow, female',
    value: 'Willow',
    src: 'https://dhwyjedqg9b7u.cloudfront.net/google/samples/en-AU-Neural2-A_FEMALE.mp3',
  },
];
