import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Flex,
  Grid,
  Image,
  Heading,
  Text,
  Button,
  Divider,
  Select,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  useToast,
  Link,
  Container,
} from '@chakra-ui/react';
import { CREATE_STORYBOARD_RESET } from '../constants/contentConstants';
import { AiVoiceOver } from '../data/formOptionData';
import { getScript, createContent, getFont } from '../actions/contentActions';
import Loader from '../components/Loading';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';

const ScriptScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const AiScript = useSelector((state) => state.scriptReducer);
  const { loading, script, error } = AiScript;

  const contentSubmited = useSelector((state) => state.createContentReducer);
  const { loading: loadingContent, contentCreated, error: errorContent } = contentSubmited;

  const AiFont = useSelector((state) => state.fontReducer);
  const { loadingFont, font, errorFont } = AiFont;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [voice, setVoice] = useState('');
  const [aiAudio, setAiAudio] = useState('');
  const [fontFamily, setFontFamily] = useState('');

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }

    dispatch(getScript());
    dispatch(getFont());

    if (contentCreated) {
      toast({
        title: 'Generating Video.',
        description: "We're creating your vedio on complition it will be available on dashboard.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      dispatch({ type: CREATE_STORYBOARD_RESET });

      navigate('/dashboard');
    }
  }, [contentCreated, dispatch, navigate]);

  const handleAudioChange = (e) => {
    e.preventDefault();

    setVoice(e.target.value);

    const AiVoice = AiVoiceOver.find((i) => i.value === e.target.value);
    setAiAudio(AiVoice.src);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const userPrompt = JSON.parse(localStorage.getItem('UserPrompt'));

    const storyboard = {
      videoName: script.data.title,
      videoDescription: script.data.title,
      videoWidth: userPrompt.resolutionvalue.value.videoWidth,
      videoHeight: userPrompt.resolutionvalue.value.videoHeight,
      language: 'en',
      audio: {
        autoBackgroundMusic: true,
        backGroundMusicVolume: 0.3,
        aiVoiceOver: {
          speaker: voice,
          speed: 100,
          amplifyLevel: 0,
        },
      },
    };

    // const breakScript = script.data.script.split('\n\n');
    const paragraphs = script.data.script
      .split(/\n\r?|\r\n?/)
      .filter((paragraph) => paragraph.trim());
    let lines = [];
    for (let i = 0; i < paragraphs.length; i++) {
      lines = lines.concat(paragraphs[i].replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|'));
    }

    const scenesArray = [];
    for (let i = 0; i < lines.length; i++) {
      // if ((breakScript[i].trim().length = 0)) continue;
      scenesArray.push({
        text: lines[i],
        fontFamily: fontFamily,
        fontSize: userPrompt.resolutionvalue.value.videoHeight === 1920 ? 38 : 32,
        fontWeight: 'normal',
        // textAlignment: 'center',
        alignment: 'center',
        voiceOver: true,
        splitTextOnNewLine: true,
        splitTextOnPeriod: true,
        textColor: 'rgba(255,255,255,1)',
        textBackgroundColor: 'rgba(236,0,0,1)',
      });
    }

    storyboard.scenes = scenesArray;

    dispatch(createContent(storyboard, userPrompt, script));
  };
  return (
    <>
      <Flex mb="5">
        <Button as={RouterLink} to="/" colorScheme="gray">
          Go Back
        </Button>
      </Flex>

      <Flex justifyContent="center" alignItems="center">
        {loading ? (
          <Loader message="Generating Script, Please wait....." />
        ) : error ? (
          <Message type="error">{error}</Message>
        ) : (
          <Grid
            templateColumns={{
              sm: '1fr',
              md: '1fr',
              lg: '1fr 1fr',
            }}
            gap="8"
          >
            <Container boxShadow="lg" rounded="md" bg="BlackAlpha 500" p="10">
              <Flex alignItems="center" justifyContent="center">
                <Heading m="3" color="BlackAlpha 300" textTransform="uppercase">
                  {script?.data?.title}
                </Heading>
              </Flex>
              <Text color="BlackAlpha 800" whiteSpace="pre-line">
                {script?.data?.script}
              </Text>
            </Container>
            <FormContainer>
              <form onSubmit={submitHandler}>
                <FormControl id="voiceover" m="3" isRequired>
                  <FormLabel>Voice-over</FormLabel>
                  <Select
                    placeholder="-- Select --"
                    value={voice}
                    onChange={(e) => handleAudioChange(e)}
                  >
                    {AiVoiceOver.map((i) => (
                      <option key={i.name} value={i.value}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                  <Flex p="5" boxShadow="lg" rounded="md">
                    <audio id="audioPlayer" controls src={aiAudio} />
                  </Flex>
                </FormControl>
                {/* fontFamily: string, optional, default - Aerial 
                fontSize:integer, optional, default - 32 
                fontWeight: string, optional,default - normal 
                alignment: string, optional, default - left */}
                <FormControl id="AiFont" m="3">
                  <FormLabel>Font</FormLabel>
                  <Select
                    placeholder="-- Select --"
                    value={fontFamily}
                    onChange={(e) => setFontFamily(e.target.value)}
                  >
                    {font.map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button type="submit" color="#48BB78" mt="4" isLoading={loadingContent}>
                  Generate Video
                </Button>
              </form>
            </FormContainer>
          </Grid>
        )}
      </Flex>
    </>
  );
};

export default ScriptScreen;
