export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAIL = 'TOKEN_FAIL';

export const SCRIPT_REQUEST = 'SCRIPT_REQUEST';
export const SCRIPT_SUCCESS = 'SCRIPT_SUCCESS';
export const SCRIPT_FAIL = 'SCRIPT_FAIL';

export const CONTENTS_REQUEST = 'CONTENTS_REQUEST';
export const CONTENTS_SUCCESS = 'CONTENTS_SUCCESS';
export const CONTENTS_FAIL = 'CONTENTS_FAIL';

export const CREATE_CONTENT_REQUEST = 'CREATE_CONTENT_REQUEST';
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const CREATE_CONTENT_FAIL = 'CREATE_CONTENT_FAIL';
export const CREATE_STORYBOARD_RESET = 'CREATE_STORYBOARD_RESET';

export const FONT_REQUEST = 'FONT_REQUEST';
export const FONT_SUCCESS = 'FONT_SUCCESS';
export const FONT_FAIL = 'FONT_FAIL';

export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAIL = 'GET_CONTENT_FAIL';
