import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  tokenReducer,
  scriptReducer,
  createContentReducer,
  fontReducer,
  getContentReducer,
  contentReducer,
} from './reduces/contentReducers';
import { userLoginReducer } from './reduces/userReducers';

const reducer = combineReducers({
  getToken: tokenReducer,
  scriptReducer,
  createContentReducer,
  contentReducer,
  fontReducer,
  getContentReducer,
  userLogin: userLoginReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const initialState = { userLogin: { userInfo: userInfoFromStorage } };

const middlewares = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export default store;
