let host = 'https://services-stage.stories2life.in';
// 'http://localhost:56150';
export const StL_Apis = {
  CreateScript: host + '/script/create',
  createContent: host + '/content',
  ListContents: host + '/content',
  UserLogin: host + '/user/login',
  GetFonts: host + '/content/font',
  GetContent: host + '/content/', // add content id in params
};
