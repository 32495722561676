import axios from 'axios';
import { Apis } from '../api/thirdPartyApi';
import { StL_Apis } from '../api/stlBackApi';
import {
  TOKEN_REQUEST,
  TOKEN_FAIL,
  TOKEN_SUCCESS,
  SCRIPT_FAIL,
  SCRIPT_REQUEST,
  SCRIPT_SUCCESS,
  CREATE_CONTENT_FAIL,
  CREATE_CONTENT_REQUEST,
  CREATE_CONTENT_SUCCESS,
  FONT_FAIL,
  FONT_REQUEST,
  FONT_SUCCESS,
  CONTENTS_FAIL,
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  GET_CONTENT_FAIL,
  GET_CONTENT_REQUEST,
  GET_CONTENT_SUCCESS,
} from '../constants/contentConstants';

export const getUserToken = (client_id, client_secret) => async (dispatch) => {
  try {
    dispatch({ type: TOKEN_REQUEST });

    const configs = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(Apis.Token.EndPoint, { client_id, client_secret }, configs);

    dispatch({ type: TOKEN_SUCCESS, payload: data });
    localStorage.setItem('access_token', JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: TOKEN_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const listContents = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTENTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(StL_Apis.ListContents, config);

    dispatch({ type: CONTENTS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CONTENTS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getScript = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SCRIPT_REQUEST });

    const userPrompt = JSON.parse(localStorage.getItem('UserPrompt'));

    const {
      userLogin: { userInfo },
    } = getState();

    const configs = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(StL_Apis.CreateScript, userPrompt, configs);

    dispatch({ type: SCRIPT_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: SCRIPT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getFont = () => async (dispatch, getState) => {
  try {
    dispatch({ type: FONT_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const configs = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const {
      data: { data },
    } = await axios.get(StL_Apis.GetFonts, configs);

    dispatch({ type: FONT_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: FONT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getContent = (contentId) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONTENT_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const configs = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const {
      data: { data },
    } = await axios.get(StL_Apis.GetContent + contentId, configs);

    dispatch({ type: GET_CONTENT_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: GET_CONTENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const createContent = (storyboard, userPrompt, script) => async (dispatch, getState) => {
  // storyboard.videoName = encodeURIComponent(storyboard.videoName.replace(/\s+/g, ' ').replaceAll("'", '').replaceAll('"', '')).replaceAll('%20', ' ').trim().substring(0, 50);
  // storyboard.videoDescription = encodeURIComponent(storyboard.videoDescription.replace(/\s+/g, ' ').replaceAll("'", '').replaceAll('"', '')).replaceAll('%20', ' ').trim().substring(0, 50);
  storyboard.videoName = storyboard.videoName
    .replace(/\s+/g, ' ')
    .replaceAll('?', '')
    .replaceAll(':', '')
    .replaceAll("'", '')
    .replaceAll('"', '')
    .trim()
    .substring(0, 50);
  storyboard.videoDescription = storyboard.videoDescription
    .replace(/\s+/g, ' ')
    .replaceAll('?', '')
    .replaceAll(':', '')
    .replaceAll("'", '')
    .replaceAll('"', '')
    .trim()
    .substring(0, 50);

  try {
    dispatch({ type: CREATE_CONTENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'application/json',
      },
    };

    const {
      data: { success },
    } = await axios.post(
      StL_Apis.createContent,
      {
        storyboard,
        userPrompt,
        script,
      },
      config,
    );

    dispatch({ type: CREATE_CONTENT_SUCCESS, payload: success });
  } catch (err) {
    dispatch({
      type: CREATE_CONTENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
