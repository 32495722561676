import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import ScriptScreen from './screens/ScriptScreen';
import Dashboard from './screens/Dashboard';
import VideoRender from './components/VideoRenderComponent';
import LoginScreen from './screens/LoginScreen';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Flex as="main" direction="column" mt="72px" py="6" px="6" bgColor="WhiteAlpha 900">
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/" element={<HomeScreen />} />
            <Route path="/script" element={<ScriptScreen />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stream/:id" element={<VideoRender />} />
          </Routes>
        </Flex>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
